import { useAtomValue } from "jotai";
import PriceChartHeader from "./PriceChartHeader";
import TradingViewWidget from "./TradingViewWidget";
import ImgTradingView from "@/assets/images/trading-view.png";
import { isMobileAtom } from "@/atoms/layout.atom";
import tw from "@/utils/twmerge";
import { useMemo } from "react";

function PriceChart() {
  const isMobile = useAtomValue(isMobileAtom);

  const tradingViewLogo = useMemo(() => {
    return (
      <a
        href="https://www.tradingview.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={ImgTradingView}
          alt="trading view"
          className="w-8 aspect-square"
        />
      </a>
    );
  }, []);

  return (
    <div
      className={tw(
        "flex flex-col flex-1",
        isMobile
          ? "gap-6"
          : "gap-4 p-6 rounded-3xl border-[1.5px] border-[#222436] bg-black-80",
      )}
    >
      <PriceChartHeader />
      <div className="flex flex-col flex-1">
        <TradingViewWidget />
      </div>
      <div className="flex items-start gap-4">
        {!isMobile && tradingViewLogo}
        <p className="font-normal text-2xs/none text-gray-100 w-[20.75rem] max-w-full">
          Charts are powered by TradingView, a multi-functionality platform that
          provides in-depth information on every aspect of trading, including
          technical and fundamental data, news and analysis, ideas, and
          community discussions, and allows you to follow real-time prices on
          the{" "}
          <a
            href="https://www.tradingview.com/symbols/BTCUSD/"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            bitcoin chart
          </a>
          .
        </p>
      </div>
      {isMobile && tradingViewLogo}
    </div>
  );
}

export default PriceChart;
