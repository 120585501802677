import ImgBetaLaunchDesktop from "@/assets/images/launch/beta-launch-desktop.png";
import ImgBetaLaunchMobile from "@/assets/images/launch/beta-launch-mobile.png";
import ImgPreLaunchDesktop from "@/assets/images/launch/pre-launch-desktop.png";
import ImgPreLaunchMobile from "@/assets/images/launch/pre-launch-mobile.png";
import ImgOfficialLaunchDesktop from "@/assets/images/launch/official-launch-desktop.png";
import ImgOfficialLaunchMobile from "@/assets/images/launch/official-launch-mobile.png";
import { LAUNCHING_PHASE } from "./constants";
import { useAtomValue } from "jotai";
import { isMobileAtom } from "@/atoms/layout.atom";
import dayjs from "@/utils/dayjs";

function Banner({
  desktopImg,
  mobileImg,
  alt,
}: {
  desktopImg: string;
  mobileImg: string;
  alt: string;
}) {
  const isMobile = useAtomValue(isMobileAtom);
  return (
    <img
      src={isMobile ? mobileImg : desktopImg}
      alt={alt}
      className="w-full h-auto max-w-full rounded-2xl sm:rounded-3xl"
    />
  );
}

function LaunchingBanner() {
  const current = dayjs.utc();
  const isBeta = current.isBefore(LAUNCHING_PHASE.PRE);
  const isPre = current.isBefore(LAUNCHING_PHASE.OFFICIAL);

  if (isBeta) {
    return (
      <a
        href="https://medium.com/@7k_ag_/the-beta-launch-of-7k-aggregator-your-leading-liquidity-hub-for-smart-traders-on-sui-439b317e83cc"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Banner
          desktopImg={ImgBetaLaunchDesktop}
          mobileImg={ImgBetaLaunchMobile}
          alt="Beta Launch"
        />
      </a>
    );
  }

  if (isPre) {
    return (
      <Banner
        desktopImg={ImgPreLaunchDesktop}
        mobileImg={ImgPreLaunchMobile}
        alt="Pre Launch"
      />
    );
  }

  return (
    <a
      href="https://medium.com/@7k_ag_/7k-aggregator-all-roads-to-the-official-launch-13d52471aa54"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Banner
        desktopImg={ImgOfficialLaunchDesktop}
        mobileImg={ImgOfficialLaunchMobile}
        alt="Official Launch"
      />
    </a>
  );
}

export default LaunchingBanner;
