import { openTxSheetAtom } from "@/atoms/layout.atom";
import { AnimatePresence } from "framer-motion";
import { useAtom, useAtomValue } from "jotai";
import {
  Sheet,
  SheetCloseEsc,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/UI/Sheet";
import { pendingTxNumAtom, txStatusListAtom } from "@/atoms/tx.atom";
import { useMemo } from "react";
import EmptyData from "@/components/EmptyData/EmptyData";

const TxStatusSheet = () => {
  const [openSheet, setOpenSheet] = useAtom(openTxSheetAtom);
  const txStatusList = useAtomValue(txStatusListAtom);
  const pendingTxNum = useAtomValue(pendingTxNumAtom);

  const pendingText = useMemo(() => {
    return pendingTxNum === 1 ? "Transaction Pending" : "Transactions Pending";
  }, [pendingTxNum]);

  return (
    <Sheet open={openSheet} onOpenChange={setOpenSheet}>
      <AnimatePresence>
        {openSheet && (
          <SheetContent
            forceMount
            side="bottom"
            className="bg-black-60 p-6 gap-6"
            onOpenChange={setOpenSheet}
          >
            <SheetHeader>
              <SheetTitle>
                {pendingTxNum} {pendingText}
              </SheetTitle>
              <SheetCloseEsc />
            </SheetHeader>
            <div className="flex flex-col gap-2">
              {txStatusList.length > 0 ? (
                txStatusList.map((tx) => (
                  <div
                    key={tx.id}
                    className="flex flex-col gap-6 p-6 bg-[#2C3043] rounded-2xl"
                  >
                    {tx.content}
                  </div>
                ))
              ) : (
                <EmptyData />
              )}
            </div>
          </SheetContent>
        )}
      </AnimatePresence>
    </Sheet>
  );
};

export default TxStatusSheet;
