import { memo, useState } from "react";
import { Skeleton } from "@/components/UI/Skeleton";
import SwapHistoryContent from "./SwapHistoryContent";
import SwapHistorySheet from "./SwapHistorySheet";
import { TradingHistoryItem } from "@/hooks/accounts/useTradingHistory";

type Props = {
  data: TradingHistoryItem;
};

function SwapHistoryItem({ data }: Props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <SwapHistoryContent
        data={data}
        onClick={() => setOpen(true)}
        className="p-4"
      />
      <SwapHistorySheet open={open} setOpen={setOpen} data={data} />
    </>
  );
}

export const SwapHistoryItemSkeleton = memo(function SwapHistoryItemSkeleton() {
  return (
    <div className="flex flex-1 flex-col rounded-2xl bg-black-60 gap-4 p-4">
      <div className="grid grid-cols-2 gap-2">
        <div className="flex items-center gap-1">
          <Skeleton className="w-4 h-4" />
          <Skeleton className="w-12 h-4" />
        </div>
        <Skeleton className="w-12 h-4" />
      </div>

      <div className="h-px bg-black-40" />

      <div className="grid grid-cols-2 gap-2">
        <div className="flex flex-col items-start gap-4">
          <span className="font-normal text-2xs/none text-gray-100">Price</span>
          <span className="text-xs/none">
            <Skeleton className="w-12 h-5" />
          </span>
        </div>

        <div className="flex flex-col items-start gap-4">
          <span className="font-normal text-2xs/none text-gray-100">
            Total value
          </span>
          <span className="text-xs/none">
            <Skeleton className="w-12 h-5" />
          </span>
        </div>
      </div>
    </div>
  );
});

export default memo(SwapHistoryItem);
