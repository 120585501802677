import { BIG_ZERO } from "@/constants/amount";
import { denormalizeTokenType } from "@/utils/token";
import { useQuery } from "@tanstack/react-query";
import BigNumber from "bignumber.js";
import { useMemo } from "react";

interface PriceChange {
  value: number;
  updateUnixTime: number;
  updateHumanTime: string;
  priceChange24h: number;
}

const useTvPairPriceChange = (pairTypes: string[]) => {
  const types = useMemo(() => pairTypes.map(denormalizeTokenType), [pairTypes]);
  const typesStr = useMemo(() => types.join(","), [types]);

  return useQuery({
    queryKey: ["getTvPairPriceChange", typesStr],
    queryFn: async () => {
      const res = await fetch(
        `${import.meta.env.VITE_BIRDEYE_API}/defi/multi_price?list_address=${typesStr}`,
        {
          method: "GET",
          headers: {
            "x-chain": "sui",
            "x-api-key": import.meta.env.VITE_BIRDEYE_API_KEY,
          },
        },
      );
      const data = await res.json();
      const priceChanges: Record<string, PriceChange> = data?.data ?? {};
      const token1Price = priceChanges[types[0]];
      const token2Price = priceChanges[types[1]];
      const relativePrice = new BigNumber(
        token1Price?.value / token2Price?.value,
      );
      const relativePriceChangePercent = new BigNumber(
        token1Price?.priceChange24h ?? 0,
      ).minus(new BigNumber(token2Price?.priceChange24h ?? 0));

      return {
        value: relativePrice.isNaN() ? BIG_ZERO : relativePrice,
        percent: relativePriceChangePercent.isNaN()
          ? BIG_ZERO
          : relativePriceChangePercent,
      };
    },
    enabled:
      !!pairTypes[0] &&
      !!pairTypes[1] &&
      pairTypes[0] !== pairTypes[1] &&
      pairTypes.length === 2,
  });
};

export default useTvPairPriceChange;
