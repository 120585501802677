import {
  Sheet,
  SheetCloseEsc,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/UI/Sheet";
import { AnimatePresence } from "framer-motion";
import { memo } from "react";
import OrderHistoryContent from "./SwapHistoryContent";
import { TradingHistoryItem } from "@/hooks/accounts/useTradingHistory";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: TradingHistoryItem;
}

function SwapHistorySheet({ open, setOpen, data }: Props) {
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <AnimatePresence>
        {open && (
          <SheetContent forceMount side="bottom" onOpenChange={setOpen}>
            <div className="flex flex-col gap-6">
              <SheetHeader>
                <SheetTitle>Swap Details</SheetTitle>
                <SheetCloseEsc />
              </SheetHeader>
              <OrderHistoryContent data={data} size="large" />
            </div>
          </SheetContent>
        )}
      </AnimatePresence>
    </Sheet>
  );
}

export default memo(SwapHistorySheet);
