import THEME from "@/constants/theme";
import { ToastContainer as TContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { TOAST_TIMEOUT } from "../constants";

export function ToastContainer() {
  return (
    <TContainer
      position="bottom-right"
      autoClose={TOAST_TIMEOUT}
      hideProgressBar
      newestOnTop={false}
      closeButton={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover={false}
      style={{
        minWidth: 380,
        maxWidth: "100%",
        paddingLeft: 0,
        paddingRight: 0,
      }}
      className="flex flex-col gap-4"
      toastStyle={{
        marginBottom: 0,
        borderRadius: 16,
        background: THEME.colors?.black?.[80],
        padding: "1.5rem",
        boxShadow: "0 4px 25px 0 rgba(11, 10, 31, 0.5)",
        color: "white",
        fontFamily: "Outfit",
        fontSize: 14,
      }}
      toastClassName="flex flex-col p-6 gap-6 bg-black-80 shadow-soft-2 shadow-skin-base/50 rounded-2xl text-white font-outfit"
    />
  );
}

export default ToastContainer;
