import { accountIncentiveAtom } from "@/atoms/account.atom";
import { useCurrentAccount } from "@mysten/dapp-kit";
import { useQuery } from "@tanstack/react-query";
import BigNumber from "bignumber.js";
import { useSetAtom } from "jotai";
import { useEffect } from "react";

interface IncentivePointResponse {
  beta_point: number;
  official_point: number;
}

const useAccountIncentivePoint = (account?: string) => {
  const setIncentivePoint = useSetAtom(accountIncentiveAtom);
  const currentAccount = useCurrentAccount();
  const accountAddress = account ?? currentAccount?.address;

  const { data } = useQuery<IncentivePointResponse>({
    queryKey: ["getAccountIncentivePoint", accountAddress],
    queryFn: async () => {
      const response = await fetch(
        `${import.meta.env.VITE_STATISTIC_API}/point?user=${accountAddress}`,
      );
      if (!response.ok) {
        throw new Error("Failed to fetch search tokens");
      }
      return await response.json();
    },
    enabled: !!accountAddress,
  });

  useEffect(() => {
    if (data) {
      setIncentivePoint({
        betaPoint: new BigNumber(data.beta_point || 0),
        officialPoint: new BigNumber(data.official_point || 0),
      });
    }
  }, [data, setIncentivePoint]);

  return data;
};

export default useAccountIncentivePoint;
