import { getTokenPrices } from "@/utils/price";
import { useQuery } from "@tanstack/react-query";

const useTokenPrices = (ids: string[]) => {
  return useQuery<Record<string, number>>({
    queryKey: ["getTokenPrices", ids],
    queryFn: async () => {
      return getTokenPrices(ids);
    },
    enabled: ids.length > 0,
  });
};

export default useTokenPrices;
