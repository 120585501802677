import { useAtomValue } from "jotai";
import SwapHistoryList from "./SwapHistoryList";
import { isMobileAtom } from "@/atoms/layout.atom";
import tw from "@/utils/twmerge";

function SwapHistory() {
  const isMobile = useAtomValue(isMobileAtom);

  return (
    <div
      className={tw(
        "flex flex-col flex-1",
        isMobile
          ? "gap-6"
          : "gap-4 p-6 rounded-3xl border-[1.5px] border-[#222436] bg-black-80",
      )}
    >
      <SwapHistoryList />
    </div>
  );
}

export default SwapHistory;
