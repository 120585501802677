import { useAtomValue } from "jotai";
import HomePage from "./pages/home";
import { isMobileAtom } from "./atoms/layout.atom";

function App() {
  const isMobile = useAtomValue(isMobileAtom);
  return isMobile !== undefined ? <HomePage /> : null;
}

export default App;
