import { isMobileAtom } from "@/atoms/layout.atom";
import { txStatusListAtom } from "@/atoms/tx.atom";
import { TOAST_TIMEOUT } from "@/components/TxStatusList/constants";
import TxToastItem, {
  TxToastItemProps,
} from "@/components/TxStatusList/TxToastItem";
import { useAtomValue, useSetAtom } from "jotai";
import { Id, toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

interface Params extends TxToastItemProps {
  id?: Id;
}

const useMutateTxStatusList = () => {
  const isMobile = useAtomValue(isMobileAtom);
  const setTxStatusList = useSetAtom(txStatusListAtom);

  const mutateTxStatusList = ({
    id,
    status,
    title,
    message,
    txHash,
  }: Params) => {
    const isLoading = status === "loading";

    const setTxItemTimeout = (itemId: Id) => {
      setTimeout(() => {
        setTxStatusList((prev) => prev.filter((tx) => tx.id !== itemId));
      }, TOAST_TIMEOUT);
    };

    const autoClose = isLoading ? false : TOAST_TIMEOUT;

    // if has id, update the existing item
    if (id) {
      if (isMobile) {
        setTxStatusList((prev) =>
          prev.map((tx) =>
            tx.id === id
              ? {
                  id,
                  status,
                  content: (
                    <TxToastItem
                      status={status}
                      title={title}
                      message={message}
                      txHash={txHash}
                    />
                  ),
                }
              : tx,
          ),
        );

        if (!isLoading) {
          setTxItemTimeout(id);
        }
      } else {
        toast.update(id, {
          render() {
            return (
              <TxToastItem
                status={status}
                title={title}
                message={message}
                txHash={txHash}
              />
            );
          },
          autoClose,
          isLoading: false,
          type: "default",
        });
      }

      return id;
    }

    // if no id, add new item
    const newId = uuidv4();
    if (isMobile) {
      setTxStatusList((prev) => [
        ...prev,
        {
          id: newId,
          status,
          content: (
            <TxToastItem
              status={status}
              title={title}
              message={message}
              txHash={txHash}
            />
          ),
        },
      ]);

      if (!isLoading) {
        setTxItemTimeout(newId);
      }
    } else {
      toast(
        <TxToastItem
          status={status}
          title={title}
          message={message}
          txHash={txHash}
        />,
        {
          toastId: newId,
          autoClose,
          isLoading: false,
          type: "default",
        },
      );
    }

    return newId;
  };

  return mutateTxStatusList;
};

export default useMutateTxStatusList;
