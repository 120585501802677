import { isMobileAtom } from "@/atoms/layout.atom";
import { useAtomValue } from "jotai";
import { AnimatePresence } from "framer-motion";
import {
  Dialog,
  DialogContentDefault,
  DialogTrigger,
} from "@/components/UI/Dialog";
import { Sheet, SheetContent, SheetTrigger } from "@/components/UI/Sheet";
import { useMemo, useState } from "react";
import Avatar from "@/components/Avatar";
import { ICChevronDown, ICSearch } from "@/assets/icons";
import { useDebounce } from "use-debounce";
import Input from "@/components/UI/Input";
import { TokenAmount, TokenDetails } from "@/types/token";
import uniqBy from "lodash/uniqBy";
import { VList } from "virtua";
import TokenItem from "./TokenItem";
import useSearchTokenList from "@/hooks/tokens/useSearchTokenList";
import { Skeleton } from "@/components/UI/Skeleton";
import Repeat from "@/components/UI/Repeat";
import EmptyData from "@/components/EmptyData/EmptyData";
import { getStaticTokenByType } from "@/utils/token";
import { strictTokenListAtom } from "@/atoms/token.atom";
import { sortTokenBalances } from "@/utils/balance";

type Props = {
  token: TokenDetails | undefined;
  setToken: (token: TokenDetails) => void;
  pivotTokenId: string;
  accountBalanceMap: Record<string, string> | undefined;
};

function SelectTokenModal({
  token,
  setToken,
  pivotTokenId,
  accountBalanceMap,
}: Props) {
  const isMobile = useAtomValue(isMobileAtom);

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debounceSearchTerm] = useDebounce(searchTerm, 200);

  const strictTokenList = useAtomValue(strictTokenListAtom);

  const { data: searchedTokens, isLoading } =
    useSearchTokenList(debounceSearchTerm);

  const _tokenBalances: TokenAmount[] = useMemo(() => {
    if (!debounceSearchTerm) {
      // default tokens
      let tokens = strictTokenList;

      // tokens from account balances
      if (accountBalanceMap) {
        Object.keys(accountBalanceMap).forEach((type) => {
          const token = getStaticTokenByType(type);
          if (token) {
            tokens.push(token);
          }
        });
      }
      // remove pivot token
      tokens = tokens.filter((token) => token.type !== pivotTokenId);

      // format token balances
      const tokenBalances: TokenAmount[] = tokens.map((token) => ({
        token,
        amount: accountBalanceMap?.[token.type] || "0",
      }));
      return uniqBy(tokenBalances, "token.type");
    }

    return (
      searchedTokens
        ?.filter((token) => token.type !== pivotTokenId)
        .map((token) => ({
          token,
          amount: accountBalanceMap?.[token.type] || "0",
        })) ?? []
    );
  }, [
    debounceSearchTerm,
    searchedTokens,
    accountBalanceMap,
    pivotTokenId,
    strictTokenList,
  ]);

  const tokenBalances = useMemo(() => {
    return sortTokenBalances(_tokenBalances);
  }, [_tokenBalances]);

  const trigger = useMemo(
    () => (
      <button
        className="flex items-center gap-2 p-3 rounded-xl"
        onClick={() => setOpen(true)}
      >
        <Avatar
          src={token?.iconUrl || ""}
          alt={token?.symbol}
          w={48}
          h={48}
          className="w-5 aspect-square"
        />
        <span className="text-sm/none">{token?.symbol}</span>
        <ICChevronDown className="w-4 aspect-square text-gray-100" />
      </button>
    ),
    [token],
  );

  const tokenList = useMemo(() => {
    if (searchTerm && isLoading) {
      return (
        <VList style={{ height: 320 }} className="scroll-container">
          <Repeat count={10}>
            <Skeleton className="h-12 p-4 bg-black-80 rounded-xl text-sm/normal mb-1" />
          </Repeat>
        </VList>
      );
    }

    if (!tokenBalances.length) {
      return <EmptyData title="No tokens found" />;
    }

    return (
      <VList style={{ height: 320 }} className="scroll-container">
        {tokenBalances.map((tokenBalance) => (
          <TokenItem
            key={tokenBalance.token.type}
            item={tokenBalance}
            onClick={(tokenBalance) => {
              setToken(tokenBalance.token);
              setOpen(false);
            }}
          />
        ))}
      </VList>
    );
  }, [isLoading, tokenBalances, searchTerm, setToken]);

  const content = useMemo(
    () => (
      <>
        <Input
          placeholder="Search coin name or type"
          prefixSlot={
            <ICSearch className="shrink-0 w-4 h-auto text-gray-100" />
          }
          postfixSlot={
            <button
              className="shrink-0 flex items-center justify-center px-2 py-1 rounded-lg bg-black-50 font-bold text-sm/none text-black-inverted-100"
              onClick={() => setOpen(false)}
            >
              ESC
            </button>
          }
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-4 bg-black-100 rounded-xl text-sm/normal"
        />

        <div className="flex flex-col gap-1">
          <div className="p-2 text-2xl/none">Token List</div>
          {tokenList}
        </div>
      </>
    ),
    [searchTerm, tokenList],
  );

  if (isMobile) {
    return (
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetTrigger asChild>{trigger}</SheetTrigger>
        <AnimatePresence>
          {open && (
            <SheetContent
              forceMount
              side="bottom"
              onOpenChange={setOpen}
              animation={false}
              className="p-4"
            >
              {content}
            </SheetContent>
          )}
        </AnimatePresence>
      </Sheet>
    );
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <AnimatePresence>
        {open && (
          <DialogContentDefault
            forceMount
            className="shadow-none flex items-start gap-4 p-0 max-w-full md:w-[24rem]"
          >
            <div className="flex-1 flex flex-col gap-4 p-1 rounded-2xl bg-[#252734] border border-black-50 backdrop-blur-md shadow-soft-3 shadow-skin-alt dark:shadow-skin-alt/10">
              {content}
            </div>
          </DialogContentDefault>
        )}
      </AnimatePresence>
    </Dialog>
  );
}

export default SelectTokenModal;
