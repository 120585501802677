import { BIG_ZERO } from "@/constants/amount";
import { CoinBalance } from "@mysten/sui/client";
import BigNumber from "bignumber.js";
import { atom } from "jotai";

export const accountBalanceListAtom = atom<CoinBalance[]>([]);

export const accountBalanceMapAtom = atom((get) => {
  const balances = get(accountBalanceListAtom);
  return balances.reduce(
    (acc, balance) => {
      acc[balance.coinType] = balance.totalBalance;
      return acc;
    },
    {} as Record<string, string>,
  );
});

export interface IncentivePoint {
  betaPoint: BigNumber;
  officialPoint: BigNumber;
}

export const accountIncentiveAtom = atom<IncentivePoint>({
  betaPoint: BIG_ZERO,
  officialPoint: BIG_ZERO,
});
