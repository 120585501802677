import { useEffect, useState } from "react";

const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    let listener: any;
    const unsupported =
      typeof window === "undefined" || typeof window.matchMedia === "undefined";
    if (unsupported) {
      setMatches(false);
    } else {
      listener = () => {
        const media = window.matchMedia(query);
        setMatches(media.matches);
      };
      listener();
      window.addEventListener("resize", listener);
    }

    return () => {
      !unsupported &&
        !!listener &&
        window.removeEventListener("resize", listener);
    };
  }, [query]);

  return matches;
};

export default useMediaQuery;
