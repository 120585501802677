import { TokenDetails } from "@/types/token";

export const SUI_TOKEN: TokenDetails = {
  type: "0x0000000000000000000000000000000000000000000000000000000000000002::sui::SUI",
  objectId:
    "0x9258181f5ceac8dbffb7030890243caed69a9599d2886d957a9cb7656af3bdb3",
  name: "Sui",
  denom: "SUI",
  decimals: 9,
  symbol: "SUI",
  iconUrl: "https://strapi-dev.scand.app/uploads/sui_c07df05f00.png",
  verified: true,
  bridgeProject: null,
};

export const USDC_TOKEN: TokenDetails = {
  type: "0x5d4b302506645c37ff133b98c4b50a5ae14841659738d6d733d59d0d217a93bf::coin::COIN",
  objectId:
    "0x4fbf84f3029bd0c0b77164b587963be957f853eccf834a67bb9ecba6ec80f189",
  name: "wUSDC (Eth)",
  symbol: "USDC",
  denom: "USDC",
  iconUrl: "https://strapi-dev.scand.app/uploads/usdc_019d7ef24b.png",
  verified: true,
  decimals: 6,
  bridgeProject: "Wormhole",
};

export const DEFAULT_SWAP_TOKENS = {
  IN: SUI_TOKEN,
  OUT: USDC_TOKEN,
};

export const SUI_TYPE = "0x2::sui::SUI";

export const SUI_FULL_TYPE = SUI_TOKEN.type;

export const POPULAR_TOKENS = [
  SUI_FULL_TYPE, // sui
  USDC_TOKEN.type, // usdc
  "0xc060006111016b8a020ad5b33834984a437aaa7d3c74c18e09a95d48aceab08c::coin::COIN", // usdt
  "0xaf8cd5edc19c4512f4259f0bee101a40d41ebed738ade5874359610ef8eeced5::coin::COIN", // weth
  "0x027792d9fed7f9844eb4839566001bb6f6cb4804f66aa2da6fe1ee242d896881::coin::COIN", // wbtc
  "0xb848cce11ef3a8f62eccea6eb5b35a12c4c2b1ee1af7755d02d7bd6218e8226f::coin::COIN", // wbnb
  "0xb7844e289a8410e50fb3ca48d69eb9cf29e27d223ef90353fe1bd8e27ff8f3f8::coin::COIN", // wsol
  "0x06864a6f921804860930db6ddbe2e16acdf8504495ea7481637a1c8b9a8fe54b::cetus::CETUS", // cetus
  "0xa99b8952d4f7d947ea77fe0ecdcc9e5fc0bcab2841d6e2a5aa00c3044e5544b5::navx::NAVX", // navx
  "0x7016aae72cfc67f2fadf55769c0a7dd54291a583b63051a5ed71081cce836ac6::sca::SCA", // sca
];
