import tw from "@/utils/twmerge";
import BaseInputNumber, { InputNumberProps } from "rc-input-number";
import { useCallback } from "react";

const PRECISION = 9;

interface Props extends Omit<InputNumberProps, "onChange"> {
  error?: boolean;
  onUserInput?: (value: string) => void;
}

function NumericalInput({
  className,
  controls = false,
  placeholder,
  formatter,
  spellCheck = "false",
  autoCorrect = "off",
  type = "text",
  precision = PRECISION,
  disabled,
  readOnly,
  onUserInput,
  ...rest
}: Props) {
  const formatNumber = useCallback(
    (num: any) => {
      const parts = num.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, precision);
      }
      return parts.join(".");
    },
    [precision],
  );

  return (
    <BaseInputNumber
      className={tw(className)}
      controls={controls}
      stringMode
      formatter={formatter ?? formatNumber}
      placeholder={placeholder ?? "0.00"}
      spellCheck={spellCheck}
      autoCorrect={autoCorrect}
      type={type}
      precision={precision}
      onChange={(val) => onUserInput?.(val as string)}
      disabled={disabled}
      readOnly={readOnly}
      {...rest}
    />
  );
}

export default NumericalInput;
