import { openTxSheetAtom } from "@/atoms/layout.atom";
import {
  errorTxNumAtom,
  pendingTxNumAtom,
  successTxNumAtom,
} from "@/atoms/tx.atom";
import { useAtomValue, useSetAtom } from "jotai";
import { memo, useMemo } from "react";
import { STATUS_COLOR_MAP, STATUS_ICON_MAP } from "../constants";
import { TxStatus } from "@/constants/transaction";

const TxStatusButton = () => {
  const setOpenTxSheet = useSetAtom(openTxSheetAtom);
  const pendingTxNum = useAtomValue(pendingTxNumAtom);
  const successTxNum = useAtomValue(successTxNumAtom);
  const errorTxNum = useAtomValue(errorTxNumAtom);

  const hasTx = useMemo(() => {
    return pendingTxNum + successTxNum + errorTxNum > 0;
  }, [pendingTxNum, successTxNum, errorTxNum]);

  const [status, txNum]: [TxStatus, number] = useMemo(() => {
    if (pendingTxNum > 0) {
      return ["loading", pendingTxNum];
    }
    if (errorTxNum > 0) {
      return ["error", errorTxNum];
    }
    if (successTxNum > 0) {
      return ["success", successTxNum];
    }
    return ["loading", 0];
  }, [pendingTxNum, successTxNum, errorTxNum]);

  const Icon = useMemo(() => {
    return STATUS_ICON_MAP[status];
  }, [status]);

  const txText = useMemo(() => {
    return txNum === 1 ? "txn" : "txns";
  }, [txNum]);

  const desc = useMemo(() => {
    return `${txNum} ${txText}`;
  }, [txNum, txText]);

  return (
    hasTx && (
      <button
        className="flex items-center gap-2 px-4 py-2 rounded-xl bg-[#252734] hover:bg-[#383D4C] text-white"
        onClick={() => {
          setOpenTxSheet(true);
        }}
      >
        <div style={{ color: STATUS_COLOR_MAP[status] }}>
          <Icon />
        </div>
        <span className="text-xs/none">{desc}</span>
      </button>
    )
  );
};

export default memo(TxStatusButton);
