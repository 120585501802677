import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

function FallBackComponent() {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center body dark">
      <div className="text-lg sm:text-2xl">Oops, something wrong.</div>
    </div>
  );
}

function ErrorBoundary({ children }: any) {
  return (
    <SentryErrorBoundary
      beforeCapture={(scope) => {
        scope.setLevel("fatal");
      }}
      fallback={FallBackComponent}
    >
      {children}
    </SentryErrorBoundary>
  );
}

export default ErrorBoundary;
