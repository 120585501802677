import InfoTabs from "./InfoTabs";
import { useAtomValue } from "jotai";
import { agInfoTabAtom } from "@/atoms/aggregator.atom";
import { SorSwapResponse } from "@/types/swapInfo";
import BatchSwapContent from "./BatchSwap/BatchSwapContent";
import PriceChart from "./PriceChart";
import tw from "@/utils/twmerge";
import SwapHistory from "./SwapHistory";
import { isMobileAtom } from "@/atoms/layout.atom";

interface Props {
  swapInfo?: SorSwapResponse;
}

function InfoTabsContainer({ swapInfo }: Props) {
  const isMobile = useAtomValue(isMobileAtom);
  const activeTab = useAtomValue(agInfoTabAtom);

  return (
    <div
      className={tw(
        "flex flex-col flex-1",
        isMobile
          ? "gap-6 p-4 rounded-3xl border-[1.5px] border-[#222436] bg-black-80"
          : "gap-2",
      )}
    >
      <InfoTabs swapInfo={swapInfo} />
      <div
        className={tw(
          "flex flex-col flex-1",
          activeTab === "price-chart" ? "flex" : "hidden",
        )}
      >
        <PriceChart />
      </div>
      <div
        className={tw(
          "flex flex-col flex-1",
          activeTab === "swap-history" ? "flex" : "hidden",
        )}
      >
        <SwapHistory />
      </div>
      <div
        className={tw(
          "flex flex-col flex-1",
          activeTab === "routes" ? "flex" : "hidden",
        )}
      >
        <BatchSwapContent swapInfo={swapInfo} />
      </div>
    </div>
  );
}

export default InfoTabsContainer;
