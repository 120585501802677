import { explorerTypeAtom } from "@/atoms/explorer.atom";
import { ExplorerType } from "@/types/explorer";
import { getDefaultStore } from "jotai";

const network = import.meta.env.VITE_NETWORK as string;

const suiVisionPrefix = network === "mainnet" ? "" : `${network}.`;
const SUIVISION = {
  HOME: `https://${suiVisionPrefix}suivision.xyz`,
  ACCOUNT: `https://${suiVisionPrefix}suivision.xyz/account`,
  TRANSACTION: `https://${suiVisionPrefix}suivision.xyz/txblock`,
  TOKEN: `https://${suiVisionPrefix}suivision.xyz/coin`,
};

const SUISCAN = {
  HOME: `https://suiscan.xyz`,
  ACCOUNT: `https://suiscan.xyz/${network}/account`,
  TRANSACTION: `https://suiscan.xyz/${network}/tx`,
  TOKEN: `https://suiscan.xyz/${network}/coin`,
};

const EMPTY = {
  HOME: "",
  ACCOUNT: "",
  TRANSACTION: "",
  TOKEN: "",
};

export function getExplorerLinks(type?: ExplorerType) {
  const explorerType = type ?? getDefaultStore().get(explorerTypeAtom);
  switch (explorerType) {
    case "suivision":
      return SUIVISION;
    case "suiscan":
      return SUISCAN;
    default:
      return EMPTY;
  }
}
