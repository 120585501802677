import { fullTokenMapAtom } from "@/atoms/token.atom";
import { SUI_FULL_TYPE, SUI_TYPE } from "@/constants/tokens";
import { SuiscanToken, TokenDetails } from "@/types/token";
import { getDefaultStore } from "jotai";
import { getExplorerLinks } from "./explorer";

export function normalizeTokenType(type: string) {
  return type === SUI_TYPE ? SUI_FULL_TYPE : type;
}

export function denormalizeTokenType(type: string) {
  return type === SUI_FULL_TYPE ? SUI_TYPE : type;
}

export function checkIsSui(type: string) {
  return type === SUI_FULL_TYPE || type === SUI_TYPE;
}

export function getStaticTokenByType(type: string): TokenDetails | undefined {
  return getDefaultStore().get(fullTokenMapAtom)[type];
}

export async function getSuiscanTokenMetadata(type: string) {
  const response = await fetch(
    `${getExplorerLinks("suiscan").HOME}/api/sui-backend/${import.meta.env.VITE_NETWORK}/api/coins/${denormalizeTokenType(type)}`,
  );
  if (!response.ok) {
    throw new Error(`Failed to fetch token metadata for ${type}`);
  }
  const data = await response.json();
  return { ...data, type: normalizeTokenType(data.type || "") } as SuiscanToken;
}
