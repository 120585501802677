import ImgLogo from "@/assets/images/logo.png";
import { Dialog, DialogContent, DialogOverlay } from "../UI/Dialog";
import { useUpdateCheck } from "react-update-notification";

function VersionUpdater() {
  const { status, reloadPage } = useUpdateCheck({
    type: "interval",
    interval: 60_000,
  });

  if (status === "checking" || status === "current") {
    return null;
  }

  return (
    <Dialog open modal>
      <DialogOverlay className="fixed z-toast" />
      <DialogContent
        className="fixed z-toast p-6 bg-skin-card shadow-soft-3 shadow-skin-alt dark:shadow-skin-alt/10 rounded-3xl"
        onPointerDownOutside={(e) => e.preventDefault()}
        onEscapeKeyDown={(e) => e.preventDefault()}
        style={{ width: "100%", maxWidth: "24rem" }}
      >
        <div className="flex flex-row items-center justify-between">
          <div className="font-bold text-lg text-skin-base">
            New Update Available
          </div>
        </div>
        <hr />
        <div className="mt-6 flex flex-col gap-6">
          <div className="flex items-center justify-center">
            <img
              src={ImgLogo}
              alt="logo"
              className="w-9 aspect-square self-center"
              style={{ width: "2.5rem" }}
            />
          </div>
          <div className="text-sm text-gray-100">
            Please refresh your browser to apply the new update
          </div>
          <button
            className="self-center min-w-[10rem] w-2/3 bg-[#383D4C] hover:bg-[#404862] px-2 h-12 text-white rounded-[10px] border-none outline-none"
            onClick={() => {
              reloadPage();
            }}
          >
            <span className="text-gradient text-sm">Refresh now</span>
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default VersionUpdater;
