import { SUI_FULL_TYPE } from "@/constants/tokens";
import { suiClient } from "@/constants/suiClient";
import BigNumber from "bignumber.js";
import { formatBalance } from "../number";
import { SUI_DECIMALS } from "@mysten/sui/utils";
import { SorSwapResponse } from "@/types/swapInfo";
import { buildTx } from "./buildTx";
import { BIG_ZERO } from "@/constants/amount";
import { getTokenPrice } from "../price";

export async function estimateGasFee(
  sorResponse: SorSwapResponse,
  accountAddress?: string,
): Promise<BigNumber> {
  if (!accountAddress) return BIG_ZERO;

  const tx = await buildTx({
    sorResponse,
    accountAddress,
    devInspect: true,
  }).catch((err) => {
    console.log("build tx error: ", err);
    return undefined;
  });

  if (!tx) return BIG_ZERO;

  const suiPrice = await getTokenPrice(SUI_FULL_TYPE);
  const suiDecimals = SUI_DECIMALS;

  const {
    effects: { gasUsed, status },
  } = await suiClient.devInspectTransactionBlock({
    sender: accountAddress,
    transactionBlock: tx,
  });

  if (status.status !== "success") return BIG_ZERO;

  const fee = new BigNumber(gasUsed.computationCost)
    .plus(gasUsed.storageCost)
    .minus(gasUsed.storageRebate);
  const feeUsd = new BigNumber(suiPrice).multipliedBy(
    formatBalance(fee, suiDecimals),
  );

  return feeUsd;
}
