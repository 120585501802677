import { memo } from "react";
import ConnectButton from "../ConnectButton";
import TxStatusButton from "@/components/TxStatusList/TxStatusButton";

function MBottomBar() {
  return (
    <div className="fixed bottom-9 left-0 right-0 z-40">
      <div className="flex items-center justify-center w-full gap-2">
        <TxStatusButton />
        <ConnectButton />
      </div>
    </div>
  );
}

export default memo(MBottomBar);
