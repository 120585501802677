import { ICShortDesc } from "@/assets/icons";
import { ESort } from "@/types/data";
import tw from "@/utils/twmerge";
import { PrimitiveAtom, useAtom } from "jotai";
import { memo, ReactNode, useCallback, useMemo } from "react";

const SortBtn = memo(function SortBtn({
  label,
  type,
  atom,
  align,
  className,
}: {
  label: ReactNode;
  type: string;
  atom: PrimitiveAtom<Record<string, ESort>>;
  align: "left" | "right";
  className?: string;
}) {
  const [sortOpts, setSortOpts] = useAtom(atom);
  const sortHandle = useCallback(() => {
    setSortOpts((opt) => {
      const val = opt[type] || ESort.DEFAULT;
      const newVal =
        val === ESort.DEFAULT
          ? ESort.DESC
          : val === ESort.DESC
            ? ESort.ASC
            : ESort.DEFAULT;
      return { [type]: newVal };
    });
  }, [setSortOpts, type]);
  const active = useMemo(
    () => type in sortOpts && sortOpts[type] !== ESort.DEFAULT,
    [sortOpts, type],
  );

  return (
    <div
      className={tw(
        "-mx-2 px-2 h-full flex items-center justify-between cursor-pointer rounded-xl font-samsung font-medium text-2xs/none border bg-[#2C3043]",
        active
          ? "border-gray-60 text-white"
          : "border-transparent text-gray-100",
        align === "left" ? "flex-row" : "flex-row-reverse",
        className,
      )}
      onClick={sortHandle}
    >
      <span>{label}</span>
      <ICShortDesc
        className={`transition-all w-4 h-auto text-skin-base ${
          active
            ? sortOpts[type] === ESort.ASC
              ? "rotate-180"
              : sortOpts[type] === ESort.DEFAULT && "opacity-0"
            : "opacity-0"
        }`}
      />
    </div>
  );
});
export default SortBtn;
