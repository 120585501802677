import EmptyTable from "@/components/EmptyData/EmptyData";
import Pagination from "@/components/Table/Pagination";
import Repeat from "@/components/UI/Repeat";
import { ESort } from "@/types/data";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";
import SwapHistoryHead from "./SwapHistoryHead";
import SwapHistoryRow, { SwapHistoryRowSkeleton } from "./SwapHistoryRow";
import { swapHistorySortOptAtom } from "@/atoms/swapHistory.atom";
import useTradingHistory from "@/hooks/accounts/useTradingHistory";
import { DEFAULT_PAGE_SIZE } from "@/constants/pagination";
import { isMobileAtom } from "@/atoms/layout.atom";
import SwapHistoryItem, { SwapHistoryItemSkeleton } from "./SwapHistoryItem";
import { useCurrentAccount } from "@mysten/dapp-kit";

function SwapHistoryList() {
  const isMobile = useAtomValue(isMobileAtom);

  const [currentPage, setCurrentPage] = useState(1);

  const sortOpts = useAtomValue(swapHistorySortOptAtom);
  const sortOpt = useMemo(() => {
    return Object.entries(sortOpts)[0] || ["timestamp", ESort.DESC];
  }, [sortOpts]);
  useEffect(() => {
    setCurrentPage(1);
  }, [sortOpt]);

  const { data, isLoading, isRefetching } = useTradingHistory();
  const transactions = useMemo(() => {
    return data?.history ?? [];
  }, [data?.history]);
  const sortedTransactions = useMemo(() => {
    return (
      [...(transactions ?? [])].sort((a, b) => {
        if (sortOpt[1] === ESort.DEFAULT) {
          return 0;
        }
        if (sortOpt[0] === "timestamp") {
          return sortOpt[1] === ESort.ASC
            ? a.timestamp - b.timestamp
            : b.timestamp - a.timestamp;
        }
        if (sortOpt[0] === "amount-in") {
          return sortOpt[1] === ESort.ASC
            ? a.amountIn.minus(b.amountIn).toNumber()
            : b.amountIn.minus(a.amountIn).toNumber();
        }
        if (sortOpt[0] === "amount-out") {
          return sortOpt[1] === ESort.ASC
            ? a.amountOut.minus(b.amountOut).toNumber()
            : b.amountOut.minus(a.amountOut).toNumber();
        }
        if (sortOpt[0] === "price") {
          return sortOpt[1] === ESort.ASC
            ? a.price.minus(b.price).toNumber()
            : b.price.minus(a.price).toNumber();
        }
        if (sortOpt[0] === "volume") {
          return sortOpt[1] === ESort.ASC
            ? a.volume.minus(b.volume).toNumber()
            : b.volume.minus(a.volume).toNumber();
        }
        return 0;
      }) ?? []
    );
  }, [sortOpt, transactions]);

  const totalPages = useMemo(() => {
    return Math.ceil((sortedTransactions.length ?? 0) / DEFAULT_PAGE_SIZE);
  }, [sortedTransactions.length]);

  const displayedTransactions = useMemo(() => {
    return sortedTransactions.slice(
      (currentPage - 1) * DEFAULT_PAGE_SIZE,
      currentPage * DEFAULT_PAGE_SIZE,
    );
  }, [currentPage, sortedTransactions]);

  const currentAccount = useCurrentAccount();
  const emptyMessage = useMemo(() => {
    return currentAccount
      ? "Empty table? Fix it with your first trade!"
      : "No data to display";
  }, [currentAccount]);

  return (
    <div className="flex flex-col gap-1">
      {isMobile ? (
        <div className="flex flex-col gap-2">
          {isLoading || isRefetching ? (
            <Repeat count={5}>
              <SwapHistoryItemSkeleton />
            </Repeat>
          ) : (
            <>
              {displayedTransactions.map((tx) => (
                <SwapHistoryItem key={tx.digest} data={tx} />
              ))}
            </>
          )}
        </div>
      ) : (
        <table className="flex flex-col gap-1">
          <SwapHistoryHead />
          <tbody className="flex flex-col gap-1">
            {isLoading || isRefetching ? (
              <Repeat count={5}>
                <SwapHistoryRowSkeleton />
              </Repeat>
            ) : (
              <>
                {displayedTransactions.map((tx) => (
                  <SwapHistoryRow key={tx.digest} data={tx} />
                ))}
              </>
            )}
          </tbody>
        </table>
      )}
      {totalPages === 0 && !isLoading && !isRefetching && (
        <EmptyTable title={emptyMessage} />
      )}
      {totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          maxVisiblePages={isMobile ? 2 : 5}
        />
      )}
    </div>
  );
}

export default SwapHistoryList;
