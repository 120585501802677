import { fullTokenMapAtom } from "@/atoms/token.atom";
import { BIG_ZERO } from "@/constants/amount";
import { TokenDetails } from "@/types/token";
import { formatBalance } from "@/utils/number";
import { getStaticTokenByType } from "@/utils/token";
import { useCurrentAccount } from "@mysten/dapp-kit";
import { useQuery } from "@tanstack/react-query";
import BigNumber from "bignumber.js";
import { useAtomValue } from "jotai";
import isEmpty from "lodash/isEmpty";

interface TradingHistoryRawItem {
  digest: string;
  timestamp: string;
  coin_in: string;
  coin_out: string;
  amount_in: string;
  amount_out: string;
  volume: string | null;
}

interface TradingHistoryRawResponse {
  count: number;
  history: TradingHistoryRawItem[];
}

export interface TradingHistoryItem {
  digest: string;
  timestamp: number;
  tokenIn?: TokenDetails;
  tokenOut?: TokenDetails;
  amountIn: BigNumber;
  amountOut: BigNumber;
  volume: BigNumber;
  price: BigNumber;
  priceInverted: BigNumber;
}

export interface TradingHistoryResponse {
  count: number;
  history: TradingHistoryItem[];
}

function mapTradingHistoryItem(
  rawItem: TradingHistoryRawItem,
): TradingHistoryItem {
  const digest = rawItem.digest;
  const timestamp = Number(rawItem.timestamp);
  const tokenIn = getStaticTokenByType(rawItem.coin_in);
  const tokenOut = getStaticTokenByType(rawItem.coin_out);
  const amountIn = formatBalance(rawItem.amount_in, tokenIn?.decimals ?? 0);
  const amountOut = formatBalance(rawItem.amount_out, tokenOut?.decimals ?? 0);
  const volume = new BigNumber(rawItem.volume ?? 0);
  const price = amountIn.dividedBy(amountOut);
  const priceInverted = amountOut.dividedBy(amountIn);
  return {
    digest,
    timestamp,
    tokenIn,
    tokenOut,
    amountIn,
    amountOut,
    volume,
    price: price.isNaN() ? BIG_ZERO : price,
    priceInverted: priceInverted.isNaN() ? BIG_ZERO : priceInverted,
  };
}

const useTradingHistory = () => {
  const currentAccount = useCurrentAccount();
  const accountAddress = currentAccount?.address;
  const fullTokenMap = useAtomValue(fullTokenMapAtom);

  return useQuery<TradingHistoryResponse>({
    queryKey: ["getTradingHistory", accountAddress, fullTokenMap],
    queryFn: async () => {
      const response = await fetch(
        `${import.meta.env.VITE_STATISTIC_API}/trading-history?addr=${accountAddress}&offset=0&limit=50`,
      );
      if (!response.ok) {
        throw new Error("Failed to fetch transactions");
      }
      const res: TradingHistoryRawResponse = await response.json();
      return {
        ...res,
        history: res.history.map(mapTradingHistoryItem),
      };
    },
    enabled: !!accountAddress && !isEmpty(fullTokenMap),
  });
};

export default useTradingHistory;
