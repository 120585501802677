import { useQuery } from "@tanstack/react-query";
import { getSuiscanTokenMetadata } from "@/utils/token";
import { SuiscanToken } from "@/types/token";

const useSuiscanTokenMetadata = (type: string, enabled: boolean = true) => {
  return useQuery<SuiscanToken>({
    queryKey: ["tokenMetadata", type],
    queryFn: async () => {
      return getSuiscanTokenMetadata(type);
    },
    enabled,
  });
};

export default useSuiscanTokenMetadata;
