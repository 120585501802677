import { useMemo, useState } from "react";
import { PriceMode } from "./types";
import dayjs from "@/utils/dayjs";
import { TradingHistoryItem } from "@/hooks/accounts/useTradingHistory";

interface Params {
  data: TradingHistoryItem;
}

const useTradingHistoryItem = ({ data }: Params) => {
  const [createdH, createdD] = useMemo(() => {
    const d = dayjs(data?.timestamp);
    return [d.format("HH:mm"), d.format("DD/MM/YYYY")];
  }, [data?.timestamp]);
  const [priceMode, setPriceMode] = useState<PriceMode>("in-out");
  const price = useMemo(() => {
    return priceMode === "in-out" ? data.price : data.priceInverted;
  }, [data.price, data.priceInverted, priceMode]);

  return {
    ...data,
    createdH,
    createdD,
    price,
    priceMode,
    setPriceMode,
  };
};

export default useTradingHistoryItem;
