import { isMobileAtom } from "@/atoms/layout.atom";
import { formatAmount } from "@bicarus/utils";
import { useSignTransaction, useSuiClient } from "@mysten/dapp-kit";
import { SuiTransactionBlockResponse } from "@mysten/sui/client";
import { Transaction } from "@mysten/sui/transactions";
import { formatAddress } from "@mysten/sui/utils";
import { useAtomValue } from "jotai";
import useMutateTxStatusList from "./useMutateTxStatusList";

export function useTxExecution() {
  const isMobile = useAtomValue(isMobileAtom);

  const client = useSuiClient();
  const { mutateAsync: signTransaction } = useSignTransaction();
  const mutateTxStatusList = useMutateTxStatusList();

  const executeTx = async (
    tx: Transaction | undefined,
    title: string,
  ): Promise<SuiTransactionBlockResponse | void> => {
    if (!tx) {
      mutateTxStatusList({
        status: "error",
        title,
        message:
          "Failed to build transaction. Please try again in a few seconds or contact support.",
      });
      throw new Error("Failed to build transaction");
    }

    const id = mutateTxStatusList({
      status: "loading",
      title,
      message: "Waiting for wallet...",
    });

    try {
      const { bytes, signature } = await signTransaction({
        transaction: tx,
      });
      mutateTxStatusList({
        id,
        status: "loading",
        title,
        message: "Executing transaction...",
      });

      const startTime = Date.now();

      const res = await client.executeTransactionBlock({
        transactionBlock: bytes,
        signature,
        options: {
          showEffects: true,
          showObjectChanges: true,
        },
      });

      const endTime = Date.now();

      if (res?.effects?.status?.status === "failure") {
        const errorMessage = res.effects.status?.error ?? "Transaction failed";
        mutateTxStatusList({
          id,
          status: "error",
          title,
          message: errorMessage,
          txHash: res.digest,
        });

        return res;
      }

      const elapsedTime = (endTime - startTime) / 1000;
      const successMessage = (
        <span>
          <span>{formatAddress(res.digest)}</span>{" "}
          <span>
            ({!isMobile && <span className="text-xs/none">Completed in </span>}~
            {formatAmount(elapsedTime)}s)
          </span>
        </span>
      );

      mutateTxStatusList({
        id,
        status: "success",
        title,
        message: successMessage,
        txHash: res.digest,
      });

      return res;
    } catch (error: any) {
      const errorMessage = error.message as string;

      mutateTxStatusList({
        id,
        status: "error",
        title,
        message: errorMessage,
      });

      throw error;
    }
  };

  return executeTx;
}
