import { ICImage } from "@/assets/icons";
import { WsrvParams } from "@/types/wsrv";
import tw from "@/utils/twmerge";

import { memo, useEffect, useMemo, useState } from "react";
type Props = JSX.IntrinsicElements["span"] &
  Partial<Pick<HTMLImageElement, "src" | "alt">> &
  WsrvParams & { fallbackSrc?: string };
function Avatar({ src: _src, alt, w, h, ...props }: Props) {
  const [shouldFallbackDefault, setShouldFallbackDefault] = useState(false);
  const [src, setSrc] = useState(_src);
  const [error, setError] = useState(0);
  const className = useMemo(() => {
    return tw(
      "relative w-full h-full rounded-full overflow-hidden bg-stake-dark-300",
      props.className,
    );
  }, [props.className]);

  useEffect(() => {
    setError(0);
    setShouldFallbackDefault(false);
  }, [_src]);
  useEffect(() => {
    const urlParams = new URLSearchParams({
      url: _src,
      ...{ w, h },
    } as any).toString();
    const src = _src ? `https://wsrv.nl/?${urlParams}` : _src;
    setSrc(src);
  }, [_src, w, h]);
  return (
    <span {...props} className={className}>
      {shouldFallbackDefault ? (
        <span className="w-full h-full inline-flex items-center justify-center">
          <ICImage className="min-w-4 max-w-20 w-1/2 h-auto" />
        </span>
      ) : (
        <img
          src={src}
          alt={alt || ""}
          style={{ objectFit: "cover" }}
          onError={() => {
            const fallback =
              error === 0 ? _src : error === 1 ? props.fallbackSrc : "";
            if (fallback) {
              setSrc(fallback);
            } else {
              setShouldFallbackDefault(true);
            }
            setError((state) => state + 1);
          }}
        />
      )}
    </span>
  );
}

export default memo(Avatar);
