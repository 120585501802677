import { DEFAULT_SWAP_TOKENS } from "@/constants/tokens";
import { TokenDetails } from "@/types/token";
import { atom } from "jotai";

export const tvPairTokensAtom = atom<[TokenDetails, TokenDetails]>([
  DEFAULT_SWAP_TOKENS.IN,
  DEFAULT_SWAP_TOKENS.OUT,
]);

export const tvPairDirectionAtom = atom<"forward" | "backward">("forward");

export const tvPairTokensWithDirectionAtom = atom((get) => {
  const [tvTokenIn, tvTokenOut] = get(tvPairTokensAtom);
  const direction = get(tvPairDirectionAtom);
  return direction === "forward"
    ? [tvTokenIn, tvTokenOut]
    : [tvTokenOut, tvTokenIn];
});

export const tvPairTypesAtom = atom((get) => {
  const tvPairTokensWithDirection = get(tvPairTokensWithDirectionAtom);
  return tvPairTokensWithDirection.map((token) => token.type);
});

export const tvSymbolAtom = atom((get) => {
  const [tvTokenIn, tvTokenOut] = get(tvPairTokensWithDirectionAtom);
  return `${tvTokenIn.symbol}/${tvTokenOut.symbol}`;
});
