import tw from "@/utils/twmerge";
import SortBtn from "@/components/Table/SortBtn";
import {
  swapHistorySortOptAtom,
  SwapHistorySortType,
} from "@/atoms/swapHistory.atom";
import { ReactNode } from "react";
import { ICInfoCircle } from "@/assets/icons";
import {
  Tooltip,
  TooltipContentGlow,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/UI/Tooltip";

const HistorySortBtn = ({
  label,
  type,
  align,
}: {
  label: ReactNode;
  type: SwapHistorySortType;
  align: "left" | "right";
}) => {
  return (
    <SortBtn
      label={label}
      type={type}
      atom={swapHistorySortOptAtom}
      align={align}
    />
  );
};

type ThProps = JSX.IntrinsicElements["th"];
function Th({ className, children }: ThProps) {
  return (
    <th
      className={tw(
        "w-full h-9 px-2 rounded-xl font-samsung font-medium text-2xs/none text-gray-100 bg-black-70",
        className,
      )}
    >
      {children}
    </th>
  );
}

function SwapHistoryHead() {
  return (
    <thead className="relative">
      <tr className="grid grid-cols-7 p-1 gap-1">
        <Th>
          <HistorySortBtn label="Time" type="timestamp" align="left" />
        </Th>
        <Th className="flex items-center">Pair</Th>
        <Th>
          <HistorySortBtn label="Input" type="amount-in" align="right" />
        </Th>
        <Th>
          <HistorySortBtn label="Output" type="amount-out" align="right" />
        </Th>
        <Th className="flex items-center justify-end">Price</Th>
        <Th>
          <HistorySortBtn
            label={
              <div className="flex items-center gap-1">
                <span>Total value</span>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger onClick={(e) => e.stopPropagation()}>
                      <ICInfoCircle className="w-4 aspect-square" />
                    </TooltipTrigger>
                    <TooltipContentGlow className="w-full max-w-[22rem]">
                      <span>Historical value at the time of transaction.</span>
                    </TooltipContentGlow>
                  </Tooltip>
                </TooltipProvider>
              </div>
            }
            type="volume"
            align="right"
          />
        </Th>
        <Th className="flex items-center justify-end">Digest</Th>
      </tr>
    </thead>
  );
}

export default SwapHistoryHead;
