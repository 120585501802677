import { useMemo } from "react";
import { getStaticTokenByType } from "@/utils/token";
import useSuiscanTokenMetadata from "./useSuiscanTokenMetadata";

const useTokenMetadata = (
  coinType: string,
  { useStatic } = { useStatic: false },
) => {
  const staticToken = useMemo(() => {
    if (useStatic) {
      return getStaticTokenByType(coinType);
    }
    return null;
  }, [useStatic, coinType]);

  const queryResult = useSuiscanTokenMetadata(
    coinType,
    useStatic ? !staticToken : true,
  );

  if (staticToken) {
    return {
      data: staticToken,
      isLoading: false,
      isError: false,
    };
  }

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading,
    isError: queryResult.isError,
  };
};

export default useTokenMetadata;
