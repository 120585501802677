import { memo, useMemo } from "react";
import BatchSwapSorRoute from "./BatchSwapSorRoute";
import { SorSwapResponse } from "@/types/swapInfo";
import { AnimatePresence, m } from "framer-motion";
import EmptyData from "@/components/EmptyData/EmptyData";
import { useAtomValue } from "jotai";
import { isMobileAtom } from "@/atoms/layout.atom";
import tw from "@/utils/twmerge";

interface Props {
  swapInfo?: SorSwapResponse;
}

function BatchSwapContent({ swapInfo }: Props) {
  const isMobile = useAtomValue(isMobileAtom);

  const content = useMemo(() => {
    if (swapInfo) {
      if (Number(swapInfo.routes?.length) > 0) {
        return (
          <m.div
            key="has-routes"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="grid gap-6"
          >
            <BatchSwapSorRoute swapInfo={swapInfo} />
          </m.div>
        );
      }

      return (
        <m.div
          key="empty-routes"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="grid gap-6"
        >
          <EmptyData title="Sorry, we couldn't find any routes for your trade. Please try again." />
        </m.div>
      );
    }

    return (
      <m.div
        key="no-data"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="grid gap-6"
      >
        <EmptyData />
      </m.div>
    );
  }, [swapInfo]);

  return (
    <div
      className={tw(
        "flex flex-col flex-1",
        isMobile
          ? "mt-6"
          : "gap-12 p-8 rounded-3xl border-[1.5px] border-[#222436] bg-black-80",
      )}
    >
      <div className="scroll-container flex-1 overflow-auto">
        <AnimatePresence>{content}</AnimatePresence>
      </div>
    </div>
  );
}

export default memo(BatchSwapContent);
