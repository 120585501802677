import { ICArrowBoth } from "@/assets/icons";
import { agTokenInAtom, agTokenOutAtom } from "@/atoms/aggregator.atom";
import { isMobileAtom } from "@/atoms/layout.atom";
import {
  tvPairDirectionAtom,
  tvPairTokensAtom,
  tvPairTokensWithDirectionAtom,
  tvPairTypesAtom,
  tvSymbolAtom,
} from "@/atoms/tradingView.atom";
import TokenGroupAvatar from "@/components/Avatar/TokenGroupAvatar";
import TextAmt from "@/components/TextAmt";
import { Skeleton } from "@/components/UI/Skeleton";
import { BIG_ZERO } from "@/constants/amount";
import useTvPairPriceChange from "@/hooks/prices/useTvPairPriceChange";
import tw from "@/utils/twmerge";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo } from "react";

function PriceChartHeader() {
  const isMobile = useAtomValue(isMobileAtom);

  const tvPairTokensWithDirection = useAtomValue(tvPairTokensWithDirectionAtom);
  const tvSymbol = useAtomValue(tvSymbolAtom);
  const [tvPairDirection, setTvPairDirection] = useAtom(tvPairDirectionAtom);

  const handleSwitchDirection = useCallback(() => {
    setTvPairDirection(tvPairDirection === "forward" ? "backward" : "forward");
  }, [tvPairDirection, setTvPairDirection]);

  const agTokenIn = useAtomValue(agTokenInAtom);
  const agTokenOut = useAtomValue(agTokenOutAtom);
  const [tvPairTokens, setTvPairTokens] = useAtom(tvPairTokensAtom);

  useEffect(() => {
    if (
      !tvPairTokens.find((token) => token.type === agTokenIn.type) ||
      !tvPairTokens.find((token) => token.type === agTokenOut.type)
    ) {
      setTvPairTokens([agTokenIn, agTokenOut]);
    }
  }, [agTokenIn, agTokenOut, tvPairTokens, setTvPairTokens]);

  const pairTypes = useAtomValue(tvPairTypesAtom);
  const { data, isLoading } = useTvPairPriceChange(pairTypes);
  const priceChangeValue = useMemo(
    () => data?.value ?? BIG_ZERO,
    [data?.value],
  );
  const priceChangePercent = useMemo(
    () => data?.percent ?? BIG_ZERO,
    [data?.percent],
  );

  const tvPairElement = useMemo(() => {
    return (
      <div className="flex items-center gap-2">
        <TokenGroupAvatar
          tokens={tvPairTokensWithDirection}
          tokenClassName="w-5 h-5 -ml-1 first:ml-0"
        />
        <span className="font-semibold text-lg/none">{tvSymbol}</span>
        <button
          className="flex items-center justify-center p-1 rounded-lg bg-[#252734] hover:bg-[#383D4C] text-gray-100 hover:text-white"
          onClick={handleSwitchDirection}
        >
          <ICArrowBoth className="w-4 aspect-square" />
        </button>
      </div>
    );
  }, [tvPairTokensWithDirection, tvSymbol, handleSwitchDirection]);

  const priceElement = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex items-center gap-2">
          <Skeleton className="w-16 h-[1.375rem]" />
          <Skeleton className="w-16 h-[1.375rem]" />
        </div>
      );
    }
    return (
      <div className="flex items-center gap-2">
        <TextAmt
          number={priceChangeValue}
          className="text-xs/none sm:text-lg/none"
        />
        <TextAmt
          number={priceChangePercent}
          suffix="%"
          precision={2}
          className={tw(
            "flex items-center justify-center px-2 py-1.5 rounded-md font-normal text-2xs/none",
            priceChangePercent.isNegative()
              ? "bg-red-80/20 text-red-80"
              : priceChangePercent.isZero()
                ? "bg-gray-80/20 text-gray-80"
                : "bg-green-80/20 text-green-80",
          )}
        />
      </div>
    );
  }, [isLoading, priceChangeValue, priceChangePercent]);

  if (isMobile) {
    return (
      <div className="flex flex-col gap-4">
        {tvPairElement}
        <div className="h-px bg-black-40" />
        {priceElement}
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between gap-4">
      {tvPairElement}
      {priceElement}
    </div>
  );
}

export default PriceChartHeader;
