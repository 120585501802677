import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import relativeTime from "dayjs/plugin/relativeTime";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);

export default dayjs;
