import { memo } from "react";
import Avatar from ".";
import { TokenDetails } from "@/types/token";

type Props = Omit<Parameters<typeof Avatar>[0], "src" | "alt"> & {
  token: TokenDetails | undefined;
};
function TokenAvatar({ token, ...props }: Props) {
  return <Avatar src={token?.iconUrl || ""} alt={token?.name} {...props} />;
}

export default memo(TokenAvatar);
