import { atom } from "jotai";
import { TokenDetails } from "@/types/token";

export const fullTokenListAtom = atom<TokenDetails[]>([]);

export const strictTokenListAtom = atom((get) => {
  const fullList = get(fullTokenListAtom);
  return fullList.filter((token) => token.verified);
});

export const fullTokenMapAtom = atom((get) => {
  const fullList = get(fullTokenListAtom);
  return fullList.reduce(
    (acc, token) => {
      acc[token.type] = token;
      return acc;
    },
    {} as Record<string, TokenDetails>,
  );
});
