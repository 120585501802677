import { VList } from "virtua";
import TokenItem from "./TokenItem";
import { useAtomValue } from "jotai";
import { accountBalanceListAtom } from "@/atoms/account.atom";
import { sortWalletBalances } from "@/utils/balance";

function TokenBalances() {
  const balances = useAtomValue(accountBalanceListAtom);

  if (!balances) {
    return null;
  }

  return (
    <VList style={{ height: 200 }} className="scroll-container">
      {sortWalletBalances(balances).map((token) => (
        <TokenItem key={token.coinType} item={token} />
      ))}
    </VList>
  );
}

export default TokenBalances;
