import { USDC_TOKEN } from "@/constants/tokens";
import { normalizeTokenType } from "./token";

interface TokenPrice {
  price: number;
  lastUpdated: number;
}

export async function getTokenPrice(
  id: string,
  vsCoin = USDC_TOKEN.type,
): Promise<number> {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_PRICES_API}/price?ids=${id}&vsCoin=${vsCoin}`,
    );
    const pricesRes: Record<string, TokenPrice> = await response.json();
    return Number(pricesRes?.[id]?.price || 0);
  } catch (error) {
    return 0;
  }
}

export async function getTokenPrices(
  ids: string[],
  vsCoin = USDC_TOKEN.type,
): Promise<Record<string, number>> {
  try {
    const normalizedIdsStr = ids.map(normalizeTokenType).join(",");
    const response = await fetch(
      `${import.meta.env.VITE_PRICES_API}/price?ids=${normalizedIdsStr}&vsCoin=${vsCoin}`,
    );
    const pricesRes: Record<string, TokenPrice> = await response.json();
    const prices = ids.reduce(
      (acc, id) => {
        acc[id] = Number(pricesRes?.[id]?.price || 0);
        return acc;
      },
      {} as Record<string, number>,
    );
    return prices;
  } catch (error) {
    return ids.reduce(
      (acc, id) => {
        acc[id] = 0;
        return acc;
      },
      {} as Record<string, number>,
    );
  }
}
