import { POPULAR_TOKENS } from "@/constants/tokens";
import { TokenAmount } from "@/types/token";
import { CoinBalance } from "@mysten/sui/client";
import BigNumber from "bignumber.js";

export function sortTokenBalances(balances: TokenAmount[]): TokenAmount[] {
  return balances.sort((a, b) => {
    // sort by popular tokens
    const aIndex = POPULAR_TOKENS.indexOf(a.token.type);
    const bIndex = POPULAR_TOKENS.indexOf(b.token.type);
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }
    if (aIndex !== -1) {
      return -1;
    }
    if (bIndex !== -1) {
      return 1;
    }

    // then sort by balance
    if (a.amount !== b.amount) {
      return new BigNumber(b.amount).comparedTo(a.amount);
    }

    // then sort by symbol
    return a.token.symbol?.localeCompare(b.token.symbol ?? "");
  });
}

export function sortWalletBalances(balances: CoinBalance[]): CoinBalance[] {
  return balances.sort((a, b) => {
    // sort by popular tokens
    const aIndex = POPULAR_TOKENS.indexOf(a.coinType);
    const bIndex = POPULAR_TOKENS.indexOf(b.coinType);
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }
    if (aIndex !== -1) {
      return -1;
    }
    if (bIndex !== -1) {
      return 1;
    }

    // then sort by balance
    const aBalance = new BigNumber(a.totalBalance);
    const bBalance = new BigNumber(b.totalBalance);
    return bBalance.comparedTo(aBalance);
  });
}
