import { TxStatus } from "@/constants/transaction";
import { atom } from "jotai";
import { ReactNode } from "react";
import { Id } from "react-toastify";

interface TxStatusItem {
  id: Id;
  status: TxStatus;
  content: ReactNode;
}
export const txStatusListAtom = atom<TxStatusItem[]>([]);

export const pendingTxNumAtom = atom((get) => {
  const txStatusList = get(txStatusListAtom);
  return txStatusList.filter((tx) => tx.status === "loading").length;
});

export const successTxNumAtom = atom((get) => {
  const txStatusList = get(txStatusListAtom);
  return txStatusList.filter((tx) => tx.status === "success").length;
});

export const errorTxNumAtom = atom((get) => {
  const txStatusList = get(txStatusListAtom);
  return txStatusList.filter((tx) => tx.status === "error").length;
});
