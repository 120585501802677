import { ICRefresh } from "@/assets/icons";
import useTradingHistory from "@/hooks/accounts/useTradingHistory";
import { useCurrentAccount } from "@mysten/dapp-kit";
import { memo } from "react";

function RefreshButton() {
  const { refetch: refetchTradingHistory } = useTradingHistory();
  const currentAccount = useCurrentAccount();

  return (
    <button
      className="w-8 aspect-square flex items-center justify-center rounded-lg bg-[#252734] hover:bg-[#383D4C] disabled:cursor-not-allowed disabled:opacity-60"
      onClick={() => refetchTradingHistory()}
      disabled={!currentAccount?.address}
    >
      <ICRefresh className="w-4 aspect-square" />
    </button>
  );
}

export default memo(RefreshButton);
