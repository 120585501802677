export const INFO_TABS = [
  {
    key: "price-chart",
    shortLabel: "Price Chart",
    label: "Price Chart",
    tooltip: null,
  },
  {
    key: "swap-history",
    shortLabel: "History",
    label: "Swap History",
    tooltip: "Your 50 latest transactions",
  },
  {
    key: "routes",
    shortLabel: "Routes",
    label: "Routes",
    tooltip: null,
  },
] as const;
