import { isMobileAtom } from "@/atoms/layout.atom";
import { useAtomValue } from "jotai";
import { memo } from "react";
import ToastContainer from "./ToastContainer";
import TxStatusSheet from "./TxStatusSheet";

function TxStatusList() {
  const isMobile = useAtomValue(isMobileAtom);
  return isMobile ? <TxStatusSheet /> : <ToastContainer />;
}

export default memo(TxStatusList);
