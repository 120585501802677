import { getDefaultStore } from "jotai";
import { denormalizeTokenType } from "@/utils/token";
import { BaseQuote, TradingViewBar } from "./types";
import { tvPairTokensWithDirectionAtom } from "@/atoms/tradingView.atom";

const supportedResolutions: string[] = [
  "5",
  "15",
  "60",
  "240",
  "1D",
  "1W",
  "1M",
];

const mapResolutionToType: Record<string, string> = {
  "5": "5m",
  "15": "15m",
  "60": "1H",
  "240": "4H",
  "1D": "1D",
  "1W": "1W",
  "1M": "1M",
};

const configurationData = {
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: supportedResolutions,
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [{ name: "crypto", value: "crypto" }],
  supports_search: false,
  supports_group_request: false,
  supports_marks: false,
  supports_timescale_marks: false,
};

export const datafeed = {
  onReady: (callback: any) => {
    setTimeout(() => {
      callback(configurationData);
    }, 0);
  },
  resolveSymbol: (
    symbolName: string,
    onSymbolResolvedCallback: any,
    onResolveErrorCallback: any,
  ) => {
    try {
      const symbolInfo = {
        pairIndex: symbolName,
        ticker: symbolName,
        name: symbolName,
        description: symbolName,
        type: "crypto",
        session: "24x7",
        timezone: "Etc/UTC",
        minmov: 1,
        pricescale: 100,
        variable_tick_size:
          "0.000001 0.01 0.00001 1 0.0001 100 0.001 1000 0.01",
        has_intraday: true,
        has_daily: true,
        // visible_plots_set: 'ohlc',
        has_weekly_and_monthly: true,
        supported_resolutions: configurationData.supported_resolutions,
        volume_precision: 2,
        // data_status: 'streaming',
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbolInfo);
      }, 0);
    } catch (error) {
      onResolveErrorCallback();
    }
  },
  getBars: async (
    _symbolInfo: any,
    resolution: string,
    periodParams: any,
    onHistoryCallback: any,
    onErrorCallback: any,
  ) => {
    try {
      const [tvTokenIn, tvTokenOut] = getDefaultStore().get(
        tvPairTokensWithDirectionAtom,
      );
      const tokenInType = tvTokenIn.type;
      const tokenOutType = tvTokenOut.type;
      const baseAddress = denormalizeTokenType(tokenInType);
      const quoteAddress = denormalizeTokenType(tokenOutType);
      const type = mapResolutionToType[resolution];
      const from = periodParams.from;
      const to = periodParams.to;
      const res = await fetch(
        `${import.meta.env.VITE_BIRDEYE_API}/defi/ohlcv/base_quote?base_address=${baseAddress}&quote_address=${quoteAddress}&type=${type}&time_from=${from}&time_to=${to}`,
        {
          method: "GET",
          headers: {
            "x-chain": "sui",
            "x-api-key": import.meta.env.VITE_BIRDEYE_API_KEY,
          },
        },
      );
      const data = await res.json();
      const items: BaseQuote[] = data.data?.items ?? [];
      const bars: TradingViewBar[] = items.map((item) => ({
        time: item.unixTime * 1000,
        open: item.o,
        high: item.h,
        low: item.l,
        close: item.c,
      }));
      onHistoryCallback(bars, { noData: bars.length === 0 });
    } catch (error) {
      onErrorCallback(error);
    }
  },
  searchSymbols: () => {},
  subscribeBars: () => {},
  unsubscribeBars: () => {},
  getMarks: () => ({}),
  getTimeScaleMarks: () => ({}),
  getServerTime: () => ({}),
};
