import LaunchingBanner from "../common/LaunchingBanner";
import NavBar from "./NavBar";
import SwapForm from "./SwapForm";

function HomePage() {
  return (
    <main className="flex flex-col gap-4 sm:gap-9 mx-auto sm:min-w-[80rem] max-w-screen-3xl">
      <div className="flex flex-col">
        <NavBar />
        <div className="flex px-6 py-4 sm:px-12 sm:py-6">
          <LaunchingBanner />
        </div>
      </div>
      <SwapForm />
    </main>
  );
}

export default HomePage;
