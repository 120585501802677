import Repeat from "@/components/UI/Repeat";
import { Skeleton } from "@/components/UI/Skeleton";
import tw from "@/utils/twmerge";
import { memo } from "react";
import TokenGroupAvatar from "@/components/Avatar/TokenGroupAvatar";
import TextAmt from "@/components/TextAmt";
import ExplorerTxLink from "@/components/ExplorerLink/ExplorerTxLink";
import { TradingHistoryItem } from "@/hooks/accounts/useTradingHistory";
import useTradingHistoryItem from "./useOrderHistoryItem";
import PriceModeBtn from "./PriceModeBtn";
import { ICChevronRight } from "@/assets/icons";
import { formatAddress } from "@mysten/sui/utils";

type TdProps = JSX.IntrinsicElements["th"];

const Td = memo(function Td({ className, ...props }: TdProps) {
  return (
    <td className={tw("flex h-12 px-2 font-normal", className)} {...props} />
  );
});

type Props = {
  data: TradingHistoryItem;
};

function SwapHistoryRow({ data }: Props) {
  const {
    createdH,
    createdD,
    tokenIn,
    tokenOut,
    price,
    priceMode,
    setPriceMode,
  } = useTradingHistoryItem({ data });

  return (
    <tr className="grid grid-cols-7 p-1 gap-1 rounded-2xl bg-[#2C3043]">
      <Td className="flex items-center">
        <div>
          <div className="mb-1 text-3xs text-gray-100">{createdH}</div>
          <div className="text-2xs">{createdD}</div>
        </div>
      </Td>
      <Td className="flex items-center">
        {tokenIn && tokenOut && (
          <div className="flex items-center gap-1">
            <TokenGroupAvatar
              tokens={[tokenIn, tokenOut]}
              tokenClassName="w-3 h-3 -ml-1 first:ml-0"
            />
            <span className="font-bold text-xs">{tokenIn.symbol}</span>
            <ICChevronRight className="w-4 aspect-square text-[#868098]" />
            <span className="font-bold text-xs">{tokenOut.symbol}</span>
          </div>
        )}
      </Td>
      <Td className="flex items-center justify-end gap-1">
        <TextAmt
          number={data.amountIn}
          className="text-xs"
          decimalClassName="text-gray-100"
        />{" "}
        <span className="text-3xs text-gray-100">{tokenIn?.symbol}</span>
      </Td>
      <Td className="flex items-center justify-end gap-1">
        <TextAmt
          number={data.amountOut}
          className="text-xs"
          decimalClassName="text-gray-100"
        />{" "}
        <span className="text-3xs text-gray-100">{tokenOut?.symbol}</span>
      </Td>
      <Td className="flex items-center justify-end">
        <div className="flex items-center gap-1">
          <TextAmt
            number={price}
            className="text-xs"
            decimalClassName="text-gray-100"
          />{" "}
          <PriceModeBtn
            tokenIn={tokenIn?.symbol || ""}
            tokenOut={tokenOut?.symbol || ""}
            value={priceMode}
            onChange={setPriceMode}
          />
        </div>
      </Td>
      <Td className="flex items-center justify-end gap-1">
        <TextAmt
          number={data.volume.isZero() ? null : data.volume}
          className="text-xs"
          decimalClassName="text-gray-100"
          prefix={<span className="text-xs text-gray-100">$</span>}
        />{" "}
      </Td>
      <Td className="flex items-center justify-end">
        <ExplorerTxLink
          txHash={data.digest}
          className="text-xs text-gray-100 underline hover:text-green-80"
        >
          {formatAddress(data.digest)}
        </ExplorerTxLink>
      </Td>
    </tr>
  );
}

export const SwapHistoryRowSkeleton = memo(function SwapHistoryRowSkeleton() {
  return (
    <tr className="grid grid-cols-7 p-1 gap-1 rounded-2xl bg-[#2C3043]">
      <Repeat count={7}>
        <Td className="flex items-center">
          <Skeleton className="w-full h-5" />
        </Td>
      </Repeat>
    </tr>
  );
});
export default memo(SwapHistoryRow);
