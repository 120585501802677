import { fullTokenListAtom } from "@/atoms/token.atom";
import { TokenDetails } from "@/types/token";
import { normalizeTokenType } from "@/utils/token";
import { useQuery } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { useEffect } from "react";

const useFullTokenList = () => {
  const setFullTokenList = useSetAtom(fullTokenListAtom);

  const { data } = useQuery<TokenDetails[]>({
    queryKey: ["getFullTokenList"],
    queryFn: async () => {
      const response = await fetch(`${import.meta.env.VITE_TOKENS_API}/all`);
      if (!response.ok) {
        throw new Error("Failed to fetch search tokens");
      }
      return response.json();
    },
  });

  useEffect(() => {
    if (Array.isArray(data)) {
      setFullTokenList(
        data.map((token) => ({
          ...token,
          type: normalizeTokenType(token.type),
        })),
      );
    }
  }, [data, setFullTokenList]);

  return data;
};

export default useFullTokenList;
