import WhitelistPasswordGuard from "./WhitelistPasswordGuard";
import { TESTING_PHASE } from "./constants";
import dayjs from "@/utils/dayjs";

interface Props {
  children: React.ReactNode;
}

function GuardList({ children }: Props) {
  const current = dayjs.utc();
  const isTestingPhaseEnd = current.isAfter(TESTING_PHASE.END);
  const isMainnetAlpha =
    import.meta.env.VITE_NETWORK === "mainnet" &&
    import.meta.env.VITE_ENV === "alpha";

  if (
    isMainnetAlpha &&
    isTestingPhaseEnd &&
    import.meta.env.VITE_WHITELIST_PASSWORD
  ) {
    return <WhitelistPasswordGuard>{children}</WhitelistPasswordGuard>;
  }

  return children;
}

export default GuardList;
