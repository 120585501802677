import {
  agAmountInAtom,
  agInfoTabAtom,
  agInfoTabClickedAtom,
} from "@/atoms/aggregator.atom";
import tw from "@/utils/twmerge";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useMemo } from "react";
import { INFO_TABS } from "./constants";
import { ICInfoCircle } from "@/assets/icons";
import {
  Tooltip,
  TooltipContentGlow,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/UI/Tooltip";
import { SorSwapResponse } from "@/types/swapInfo";
import RefreshButton from "./RefreshButton";

interface Props {
  swapInfo?: SorSwapResponse;
}

function DInfoTabs({ swapInfo }: Props) {
  const [selectedTab, setSelectedTab] = useAtom(agInfoTabAtom);
  const [infoTabClicked, setInfoTabClicked] = useAtom(agInfoTabClickedAtom);
  const amountIn = useAtomValue(agAmountInAtom);

  const selectedItem = useMemo(() => {
    return INFO_TABS.find((t) => t.key === selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (amountIn && !infoTabClicked && selectedTab !== "routes" && swapInfo) {
      setSelectedTab("routes");
    } else if (!amountIn && !infoTabClicked && selectedTab === "routes") {
      setSelectedTab("price-chart");
    }
  }, [amountIn, infoTabClicked, selectedTab, swapInfo, setSelectedTab]);

  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-1 p-1 rounded-xl bg-black-80">
        {INFO_TABS.map(({ key, label, tooltip }) => (
          <button
            key={key}
            className={tw(
              "flex items-center justify-center px-3 py-2 gap-1 transition-all rounded-lg min-h-[2.215rem]",
              key === selectedTab ? "bg-black-40" : "bg-transparent",
              "text-xs/none",
            )}
            onClick={() => {
              setSelectedTab(key);
              if (!infoTabClicked) {
                setInfoTabClicked(true);
              }
            }}
          >
            {label}
            {tooltip && (
              <div onClick={(e) => e.stopPropagation()}>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <ICInfoCircle className="w-4 aspect-square" />
                    </TooltipTrigger>
                    <TooltipContentGlow className="w-[18rem]">
                      <span>{tooltip}</span>
                    </TooltipContentGlow>
                  </Tooltip>
                </TooltipProvider>
              </div>
            )}
          </button>
        ))}
      </div>
      <div className="flex items-center gap-2">
        <div className="font-semibold text-2xl/none">{selectedItem?.label}</div>
        {selectedItem?.key === "swap-history" && <RefreshButton />}
      </div>
    </div>
  );
}

export default DInfoTabs;
