import { ICCopy, ICExport } from "@/assets/icons";
import CopyBtn from "@/components/CopyBtn";
import ExplorerTxLink from "@/components/ExplorerLink/ExplorerTxLink";
import { ReactNode } from "react";
import { STATUS_COLOR_MAP, STATUS_ICON_MAP } from "../constants";
import { TxStatus } from "@/constants/transaction";

export interface TxToastItemProps {
  status: TxStatus;
  title: ReactNode;
  message: ReactNode;
  txHash?: string;
}

const TxToastItem = ({ status, title, message, txHash }: TxToastItemProps) => {
  const Icon = STATUS_ICON_MAP[status];

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <h2 className="font-semibold text-lg">{title}</h2>
        <div className="h-px w-full bg-black-50" />
      </div>
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <div style={{ color: STATUS_COLOR_MAP[status] }}>
            <Icon />
          </div>
          <p className="text-sm/none">{message}</p>
        </div>
        {txHash && (
          <div className="flex items-center gap-2">
            <CopyBtn text={txHash} className="p-0.5">
              <ICCopy className="w-5 h-auto" />
            </CopyBtn>
            <ExplorerTxLink txHash={txHash} className="p-0.5">
              <ICExport className="w-5 h-auto" />
            </ExplorerTxLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default TxToastItem;
