import { memo, useMemo } from "react";
import tw from "@/utils/twmerge";
import TextAmt from "@/components/TextAmt";
import TokenGroupAvatar from "@/components/Avatar/TokenGroupAvatar";
import ExplorerTxLink from "@/components/ExplorerLink/ExplorerTxLink";
import { TradingHistoryItem } from "@/hooks/accounts/useTradingHistory";
import useTradingHistoryItem from "../useOrderHistoryItem";
import PriceModeBtn from "../PriceModeBtn";
import { formatAddress } from "@mysten/sui/utils";
import { ICInfoCircle } from "@/assets/icons";
import {
  Tooltip,
  TooltipContentGlow,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/UI/Tooltip";

interface Props {
  data: TradingHistoryItem;
  onClick?: () => void;
  className?: string;
  size?: "default" | "large";
}

function SwapHistoryContent({
  data,
  onClick,
  className,
  size = "default",
}: Props) {
  const {
    createdH,
    createdD,
    tokenIn,
    tokenOut,
    price,
    priceMode,
    setPriceMode,
  } = useTradingHistoryItem({ data });

  const isLarge = useMemo(() => size === "large", [size]);

  if (isLarge) {
    return (
      <div className="flex w-full">
        <div
          className={tw(
            "flex flex-1 flex-col rounded-2xl bg-black-80 gap-6 p-6",
            className,
          )}
        >
          <div className="flex flex-col gap-2">
            <p className="text-xs/none">
              <span className="font-bold text-gray-100">{createdH}</span>{" "}
              <span className="text-skin-alt">{createdD}</span>
            </p>
            {tokenIn && tokenOut && (
              <div className="grid grid-cols-2 gap-2">
                <div className="flex items-center gap-1">
                  <TokenGroupAvatar tokens={[tokenIn, tokenOut]} />
                  <span className="font-bold text-sm/none">
                    {tokenIn.symbol} - {tokenOut.symbol}
                  </span>
                </div>

                <p className="flex items-center gap-1">
                  <span className="text-sm/none text-green-80">Buy</span>
                  <TextAmt
                    number={data.amountOut}
                    className="font-normal text-sm/none text-gray-100"
                    suffix={` ${tokenOut.symbol}`}
                  />
                </p>
              </div>
            )}
          </div>

          <div className="h-px bg-black-40" />

          <div className="grid grid-cols-2 gap-2">
            <div className="flex flex-col items-start gap-4">
              <span className="flex items-center font-light text-2xs/none text-gray-100 min-h-4">
                Price
              </span>
              <span className="text-xs/none">
                <div className="flex items-center gap-1">
                  <TextAmt number={price} className="text-sm/none" />{" "}
                  <div onClick={(e) => e.stopPropagation()}>
                    <PriceModeBtn
                      tokenIn={tokenIn?.symbol || ""}
                      tokenOut={tokenOut?.symbol || ""}
                      value={priceMode}
                      onChange={setPriceMode}
                      className="bg-black-100"
                    />
                  </div>
                </div>
              </span>
            </div>

            <div className="flex flex-col items-start gap-4">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <div className="flex items-center gap-1">
                      <span className="font-light text-2xs/none text-gray-100">
                        Total value
                      </span>
                      <ICInfoCircle className="w-4 aspect-square" />
                    </div>
                  </TooltipTrigger>
                  <TooltipContentGlow className="w-full max-w-[22rem]">
                    <span>Historical value at the time of transaction.</span>
                  </TooltipContentGlow>
                </Tooltip>
              </TooltipProvider>
              <span className="text-sm/none">
                <div className="flex items-center gap-1">
                  <TextAmt
                    number={data.volume.isZero() ? null : data.volume}
                    className="text-sm/none"
                    prefix={
                      <span className="text-sm/none text-gray-100">$</span>
                    }
                  />
                </div>
              </span>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2">
            <div className="flex flex-col items-start gap-4">
              <span className="flex items-center font-light text-2xs/none text-gray-100">
                Input
              </span>
              <TextAmt
                number={data.amountIn}
                className="text-sm/none"
                suffix={` ${tokenIn?.symbol}`}
              />
            </div>

            <div className="flex flex-col items-start gap-4">
              <span className="font-light text-2xs/none text-gray-100">
                Digest
              </span>
              <ExplorerTxLink
                txHash={data.digest}
                className="text-sm/none hover:text-green-80"
              >
                {formatAddress(data.digest)}
              </ExplorerTxLink>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <a className="flex w-full" onClick={onClick}>
      <div
        className={tw(
          "flex flex-1 flex-col rounded-2xl bg-black-60 gap-4 p-4",
          className,
        )}
      >
        {tokenIn && tokenOut && (
          <div className="grid grid-cols-2 gap-2">
            <div className="flex items-center gap-1">
              <TokenGroupAvatar tokens={[tokenIn, tokenOut]} />
              <span className="font-bold text-xs/none">
                {tokenIn.symbol} - {tokenOut.symbol}
              </span>
            </div>

            <p className="flex items-center gap-1">
              <span className="text-xs/none text-green-80">Buy</span>
              <TextAmt
                number={data.amountOut}
                className="font-normal text-xs/none text-gray-100"
                suffix={` ${tokenOut.symbol}`}
              />
            </p>
          </div>
        )}

        <div className="h-px bg-black-40" />

        <div className="grid grid-cols-2 gap-2">
          <div className="flex flex-col items-start gap-4">
            <span className="font-normal text-2xs/none text-gray-100">
              Price
            </span>
            <span className="text-xs/none">
              <div className="flex items-center gap-1">
                <TextAmt number={price} className="text-xs/none" />{" "}
                <div onClick={(e) => e.stopPropagation()}>
                  <PriceModeBtn
                    tokenIn={tokenIn?.symbol || ""}
                    tokenOut={tokenOut?.symbol || ""}
                    value={priceMode}
                    onChange={setPriceMode}
                    className="bg-black-100"
                  />
                </div>
              </div>
            </span>
          </div>

          <div className="flex flex-col items-start gap-4">
            <span className="font-normal text-2xs/none text-gray-100">
              Total value
            </span>
            <span className="text-xs/none">
              <div className="flex items-center gap-1">
                <TextAmt
                  number={data.volume.isZero() ? null : data.volume}
                  className="text-xs/none"
                  prefix={<span className="text-xs/none text-gray-100">$</span>}
                />
              </div>
            </span>
          </div>
        </div>
      </div>
    </a>
  );
}

export default memo(SwapHistoryContent);
