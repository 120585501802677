import React, { Fragment, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "@mysten/dapp-kit/dist/index.css";
import "./styles/global.css";

import { getFullnodeUrl } from "@mysten/sui/client";
import {
  SuiClientProvider,
  WalletProvider,
  createNetworkConfig,
} from "@mysten/dapp-kit";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Theme } from "@radix-ui/themes";
import App from "./App.tsx";
import { uiKitDarkTheme } from "./themes/index.tsx";
import { LazyMotion } from "framer-motion";
import useTheme from "./hooks/useTheme.tsx";
import { useScreenSize } from "./hooks/dom/useScreenSize.tsx";
import { getDefaultStore } from "jotai";
import { screenSizeAtom } from "./atoms/layout.atom.ts";
import { agDebugSimulate } from "./atoms/aggregator.atom.ts";
import useFullTokenList from "./hooks/tokens/useFullTokenList.tsx";
import useAccountBalanceList from "./hooks/accounts/useAccountBalanceList.tsx";
import VersionUpdater from "./components/VersionUpdater/index.tsx";
import ReactGa from "./utils/ga.ts";
import useAccountIncentivePoint from "./hooks/accounts/useAccountIncentivePoint.tsx";
import TxStatusList from "./components/TxStatusList/index.tsx";
import { initSentry } from "./utils/sentry.ts";
import ErrorBoundary from "./components/ErrorBoundary/index.tsx";
import GuardList from "./components/GuardList/index.tsx";
// import Maintenance from "./components/Maintenance/index.tsx";

initSentry();

if (import.meta.env.VITE_GA_ID) {
  ReactGa.initialize(import.meta.env.VITE_GA_ID);
}

const loadFramerMotionFeatures = () =>
  import("@/utils/motion/domMax").then((res) => res.default);

const queryClient = new QueryClient();

const { networkConfig } = createNetworkConfig({
  localnet: { url: getFullnodeUrl("localnet") },
  devnet: { url: getFullnodeUrl("devnet") },
  testnet: { url: getFullnodeUrl("testnet") },
  mainnet: { url: getFullnodeUrl("mainnet") },
});

const GlobalHooks = () => {
  useTheme();
  const screenSize = useScreenSize();
  useEffect(() => {
    getDefaultStore().set(screenSizeAtom, screenSize);
  }, [screenSize]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const isSimulate = params.get("debug_simulate") === "true";
    getDefaultStore().set(agDebugSimulate, isSimulate);
  }, []);
  useFullTokenList();
  useAccountBalanceList();
  useAccountIncentivePoint();
  return null;
};

const ProductionErrorBoundary =
  process.env.NODE_ENV === "production" ? ErrorBoundary : Fragment;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Theme appearance="dark">
      <QueryClientProvider client={queryClient}>
        <SuiClientProvider
          networks={networkConfig}
          defaultNetwork={import.meta.env.VITE_NETWORK || "devnet"}
        >
          <WalletProvider
            theme={uiKitDarkTheme}
            autoConnect
            stashedWallet={{
              name: "7K Aggregator",
            }}
          >
            <LazyMotion features={loadFramerMotionFeatures}>
              <ProductionErrorBoundary>
                <GuardList>
                  <GlobalHooks />
                  <TxStatusList />
                  {/* <Maintenance /> */}
                  <VersionUpdater />
                  <App />
                </GuardList>
              </ProductionErrorBoundary>
            </LazyMotion>
          </WalletProvider>
        </SuiClientProvider>
      </QueryClientProvider>
    </Theme>
  </React.StrictMode>,
);
