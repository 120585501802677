import { getExplorerLinks } from "@/utils/explorer";
import React, { memo, useMemo } from "react";

type Props = Omit<JSX.IntrinsicElements["a"], "href"> & {
  account: string;
};

const ExplorerAccountLink = React.forwardRef<HTMLAnchorElement, Props>(
  function ExplorerAccount({ account, ...props }, ref) {
    const link = useMemo(() => {
      return `${getExplorerLinks().ACCOUNT}/${account}`;
    }, [account]);
    return (
      <a
        target="_blank"
        title={link}
        rel="noreferrer"
        {...props}
        ref={ref}
        href={link}
      />
    );
  },
);

export default memo(ExplorerAccountLink);
