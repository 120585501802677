import { ICExport } from "@/assets/icons";
import Avatar from "@/components/Avatar";
import ExplorerTokenLink from "@/components/ExplorerLink/ExplorerTokenLink";
import TextAmt from "@/components/TextAmt";
import { TokenAmount } from "@/types/token";
import { formatBalance } from "@/utils/number";
import { getStaticTokenByType } from "@/utils/token";
import { formatAddress } from "@mysten/sui/utils";
import ImgVerified from "@/assets/images/verified.png";

type Props = {
  item: TokenAmount;
  onClick: (item: TokenAmount) => void;
};

function TokenItem({ item, onClick }: Props) {
  return (
    <button
      className="h-12 w-full flex items-center justify-between gap-2 p-2 mb-1 bg-black-50 rounded-lg"
      onClick={() => onClick(item)}
    >
      <div className="flex items-center gap-2">
        <Avatar
          src={
            item.token.iconUrl ||
            getStaticTokenByType(item.token.type)?.iconUrl ||
            ""
          }
          alt={item.token.symbol}
          w={48}
          h={48}
          className="w-6 aspect-square"
        />
        <div className="flex flex-col items-start gap-1">
          <div className="flex items-center gap-1">
            <span className="truncate text-sm/none">{item.token.symbol}</span>
            {item.token.verified && (
              <img
                src={ImgVerified}
                alt="verified"
                className="w-2.5 aspect-square rounded-[0.625rem]"
              />
            )}
          </div>
          <span className="truncate text-2xs/none font-normal text-gray-100">
            {item.token.name}{" "}
            {item.token.bridgeProject &&
              `(Portal from ${item.token.bridgeProject})`}
          </span>
        </div>
      </div>
      <div className="flex flex-col justify-end gap-1 items-end">
        <TextAmt
          number={formatBalance(item.amount, item.token.decimals ?? 0)}
        />
        <ExplorerTokenLink
          tokenId={item.token.type}
          className="flex items-center gap-1 truncate text-2xs/none font-normal text-gray-100 hover:text-green-80"
          onClick={(e) => e.stopPropagation()}
        >
          <span>{formatAddress(item.token.type)}</span>
          <ICExport className="w-2.5 aspect-square" />
        </ExplorerTokenLink>
      </div>
    </button>
  );
}

export default TokenItem;
