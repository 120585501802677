import { isMobileAtom } from "@/atoms/layout.atom";
import { useAtomValue } from "jotai";
import MInfoTabs from "./MInfoTabs";
import DInfoTabs from "./DInfoTabs";
import { SorSwapResponse } from "@/types/swapInfo";

interface Props {
  swapInfo?: SorSwapResponse;
}

function InfoTabs({ swapInfo }: Props) {
  const isMobile = useAtomValue(isMobileAtom);

  return (
    <>
      {isMobile ? (
        <MInfoTabs swapInfo={swapInfo} />
      ) : (
        <DInfoTabs swapInfo={swapInfo} />
      )}
    </>
  );
}

export default InfoTabs;
